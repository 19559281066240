/* eslint-disable max-lines */
import { Capacitor } from "@capacitor/core";
import * as Sentry from "@sentry/capacitor";
import * as Client from "modules/client";
import * as Configuration from "modules/configuration";
import { ErrorBoundary } from "modules/error-boundary";
import React, { Suspense, useEffect } from "react";
import { Router, useHistory } from "react-router-dom";
import { initCapacitorPlugins, installReferrerObj, installReferrerResponseCode } from "../modules/capacitor";
import { Provider as CapacitorProvider, useCapacitorStoreDispatchContext } from "../modules/capacitor/store/provider";
import { Instance, sendDeviceInfo } from "../modules/client/api";
import { AppUrlListener } from "./app-url-listener/AppUrlListener";
import { history } from "./history";
import "./index.scss";
import { LocalizedSwitch } from "./locale";
import { LocalizedProvider } from "./locale/components/provider";
import { routes } from "./routes";
// Suspense fallback не должен ли быть тут лоадер?
export const Provider: React.FC<React.PropsWithChildren> = () => {
    return (
        <ErrorBoundary>
            <Suspense fallback={null}>
                <Router history={history}>
                    <AppUrlListener />
                    <CapacitorProvider>
                        <LocalizedProvider>
                            <Client.Provider>
                                <Client.Api.ConditionsProvider>
                                    <Configuration.Provider>
                                        <ProviderContent/>
                                        <LocalizedSwitch>{routes()}</LocalizedSwitch>
                                    </Configuration.Provider>
                                </Client.Api.ConditionsProvider>
                            </Client.Provider>
                        </LocalizedProvider>
                    </CapacitorProvider>
                </Router>
            </Suspense>
        </ErrorBoundary>
    );
};
const ProviderContent: React.FC<React.PropsWithChildren> = (): null => {
    const state: Client.State = Client.useStateContext();
    const capacitorStoreDispatch = useCapacitorStoreDispatchContext();
    const reactHistory = useHistory();

    useEffect(() => {
        initCapacitorPlugins(reactHistory, capacitorStoreDispatch).then(async (result): Promise<void> => {
            if (!Capacitor.isNativePlatform()) {
                return;
            }
            sendFacebookDeferredDeepLink(state.api, result.deferredFacebookDeepLink || localStorage.getItem("capacitor:facebook-deferred-deep-link"), result.deviceInfo?.deviceId);
            await sendDeviceInfo(state.api);
            sendDevicePushToken(state.api);
            sendGooglePlayReferrer(state.api);
        });
    }, []);

    useEffect((): void => {
        if (state.type === "user") {
            sendDevicePushToken(state.api);
        }
    }, [state.type]);

    return null;
};

const sendDevicePushToken = (api: Instance): void => {
    const FCMToken: string | null = localStorage.getItem("capacitor:token");

    if (FCMToken) {
        api.user.pushNotifications.postFcmDevice(FCMToken, Capacitor.getPlatform()).catch((error) => {
            console.warn("Push device token send error: ", { error });
            Sentry.captureException("api.user.pushNotifications.postFcmDevice error", error);
        });
    }
};

const sendGooglePlayReferrer = (api: Instance): void => {
    /** На данный момент плагин поддерживает только android, IOS мы пока не поддерживаем*/
    if (Capacitor.getPlatform() !== "android") {
        return;
    }

    window.installReferrer?.getReferrer(
        (googlePlayReferrer: installReferrerObj): void => {
            if (!googlePlayReferrer) {
                return;
            }

            const isNeverSent: boolean = localStorage.getItem("capacitor:install-referrer") !== JSON.stringify(googlePlayReferrer);
            const deviceId: string | null = localStorage.getItem("capacitor:device-id");
            let utmSource: string = googlePlayReferrer.utm_source || "(not_parsed)";
            let utmMedium: string = googlePlayReferrer.utm_medium || "(not set)";

            if (googlePlayReferrer.responseCode === installReferrerResponseCode.FEATURE_NOT_SUPPORTED) {
                utmSource = "(not_supported)";
                utmMedium = "(not set)";
            }

            if (googlePlayReferrer.responseCode === installReferrerResponseCode.SERVICE_UNAVAILABLE) {
                utmSource = "(not_available)";
                utmMedium = "(not set)";
            }

            if (isNeverSent && deviceId) {
                api.user.deviceInfo
                    .postDeviceLead(deviceId, utmSource, utmMedium, googlePlayReferrer.originalReferrer)
                    .then((): void => {
                        localStorage.setItem("capacitor:install-referrer", JSON.stringify(googlePlayReferrer));
                    })
                    .catch((error): void => {
                        console.error(error);
                        Sentry.captureException("api.user.deviceInfo.postDeviceLead error", error);
                        localStorage.removeItem("capacitor:install-referrer");
                    });
            }
        },
        (error: string): void => {
            console.warn("window.installReferrer.getReferrer error: ", error);
        },
    );
};

const sendFacebookDeferredDeepLink = (api: Instance, link: string | null, deviceId: string | undefined): void => {
    if (!Capacitor.isNativePlatform()) {
        return;
    }

    if (!link) {
        return;
    }

    if (!deviceId) {
        Sentry.captureException({
            message: "FacebookLogin.getDeferredDeepLink no deviceId",
            extra: {
                deferredDeepLink: link,
            },
        });
        return;
    }

    let facebookDeepLinkSearchParams: URLSearchParams;

    try {
        facebookDeepLinkSearchParams = new URLSearchParams(new URL(link).search);
    } catch (e) {
        Sentry.captureException({
            message: "FacebookLogin.getDeferredDeepLink URL parse error",
            extra: {
                deferredDeepLink: link,
            },
        });

        return;
    }

    const utmSource= facebookDeepLinkSearchParams.get("utm_source");
    const utmMedium= facebookDeepLinkSearchParams.get("utm_medium");

    if (utmSource === null || utmMedium === null) {
        Sentry.captureException({
            message: "FacebookLogin.getDeferredDeepLink utm error",
            level: "error",
            extra: {
                utmSource,
                utmMedium,
                deferredDeepLink: link,
            },
        });

        return;
    }

    api.user.deviceInfo
        .postDeviceLead(deviceId, utmSource, utmMedium, link)
        .then((): void => {
            localStorage.removeItem("capacitor:facebook-deferred-deep-link");
            const logObj = {
                utmSource,
                utmMedium,
                deferredDeepLink: link,
            };

            console.log("FacebookLogin.getDeferredDeepLink success", logObj);

            Sentry.captureEvent({
                message: "FacebookLogin.getDeferredDeepLink success",
                level: "info",
                extra: logObj,
            });
        })
        .catch((error): void => {
            console.error(error);
            Sentry.captureException({
                message: "FacebookLogin.getDeferredDeepLink error",
                extra: {
                    error,
                    utmSource,
                    utmMedium,
                    deferredDeepLink: link,
                },
            });
        });
};
Provider.displayName = "App.Provider";
