import { useInputState } from "@horat1us/react-hooks";
import { format } from "@react-input/mask";
import classNames from "classnames";
import * as Chat from "modules/chat";
import { PhoneInput, PhoneInputDefaultFormatProps } from "modules/react-phone-input";
import * as React from "react";
import * as State from "../state";

export const RequestFormMessage: React.FC<
    React.PropsWithChildren<State.MessageProps<Chat.SignUp.RequestFormMessage>>
> = (props) => {
    const dispatch = Chat.useDispatchContext();
    const [value, handleChange] = useInputState(format(props.value.phone.slice(3), PhoneInputDefaultFormatProps) || "+234");
    const rawValue = value.replace(/\D/g, "");
    const isSubmitEnable = rawValue.length === 13;
    const handleSubmit = React.useCallback((e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!isSubmitEnable) {
            return false;
        }
        dispatch(new Chat.ReplaceAction([new Chat.TextMessage(value, "user"), new Chat.SignUp.RequestMessage(rawValue)]));
    }, [dispatch, rawValue, value, isSubmitEnable]);

    return (
        <form
            onSubmit={handleSubmit}
            className="message user-message editing">
            <div className="form-group">
                <PhoneInput
                    onChange={handleChange}
                    value={value}
                    id="sign-phone"
                    className="form__control"
                    type="tel"
                    autoFocus />
                <i className="icon icon_phone" />
                <div className="btn-container v-align">
                    <button
                        id={"sign-phone-submit"}
                        type="submit"
                        className={classNames("btn btn_action", isSubmitEnable ? "enabled" : "disabled")}>
                        <i className="icon icon_arrow" />
                    </button>
                </div>
            </div>
        </form>
    );
};
